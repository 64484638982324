.DayPicker {
  font-size: 2rem;
}
.DayPicker-Day--today {
  color: #cb4343 !important;
}
.calendar-row {
  display: flex;
}

.calendar-row > .calendar {
  flex: 0 0 auto;
}

.time-slots {
  flex: 1 1 auto;
}

.time-slots > h3 {
  font-weight: 500;
  font-size: 23px;
}

.booking-page .time-slots {
  position: relative;
  margin-top: 20px;
}

.booking-page .time-slots .day-division {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 0 10px;
  margin-bottom: 16px;
  background: #e3ebf8;
}

.booking-page .time-slots .day-division h3 {
  margin: 10px 0 10px 0;
  font-size: 18px;
}

.booking-page .time-slots .time-slot {
  font-size: 16px;
  color: #fff;
  background-color: #4a90e2;
  padding: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  width: 75px;
  margin-right: 10px;
  display: inline-block;
  text-align: center;
  border-radius: 5px;
}
.booking-page .time-slots .time-slot:hover {
  background-color: #3679c7;
}
.booking-page .time-slots .time-slot .info {
  font-size: 15px;
  display: none;
}
.booking-page .time-slots .time-slot.m1-user {
  width: 150px;
}

.booking-page .time-slots .time-slot.m1-user.full {
  background: #8ea5c1;
}
.booking-page .time-slots .time-slot.m1-user.full:hover {
  background-color: #4a90e2;
}
.booking-page .time-slots .time-slot.m1-user .info {
  display: block;
}
.booking-page .time-slots .time-slot.m1-user .info .counts {
  margin-top: 5px;
  display: flex;
}
.booking-page .time-slots .time-slot.m1-user .info .counts > div {
  flex: 1 1 auto;
  text-align: center;
  border: 1px solid rgb(131, 170, 220);
}

.booking-page .time-slots .time-slot .reserve-highlight {
  font-size: 16px;
  display: none;
}

.booking-page .time-slots .time-slot:hover .reserve-highlight {
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .booking-page .time-slots {
    padding: 0;
  }
}

.time-slots-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  background: #ffffff9e;
  z-index: 1000;
  left: 0;
  right: 0;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid rgb(74, 144, 226);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(74, 144, 226) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.lds-grid-loading {
  font-size: 28px;
  color: rgb(74, 144, 226);
}
.lds-grid {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-grid div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(74, 144, 226);
  animation: lds-grid 1.2s linear infinite;
}
.lds-grid div:nth-child(1) {
  top: 6px;
  left: 6px;
  animation-delay: 0s;
}
.lds-grid div:nth-child(2) {
  top: 6px;
  left: 26px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(3) {
  top: 6px;
  left: 45px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(4) {
  top: 26px;
  left: 6px;
  animation-delay: -0.4s;
}
.lds-grid div:nth-child(5) {
  top: 26px;
  left: 26px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(6) {
  top: 26px;
  left: 45px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(7) {
  top: 45px;
  left: 6px;
  animation-delay: -0.8s;
}
.lds-grid div:nth-child(8) {
  top: 45px;
  left: 26px;
  animation-delay: -1.2s;
}
.lds-grid div:nth-child(9) {
  top: 45px;
  left: 45px;
  animation-delay: -1.6s;
}
@keyframes lds-grid {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 200px;
  height: 200px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 128px;
  height: 128px;
  margin: 8px;
  border: 8px solid rgb(74, 144, 226);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(74, 144, 226) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
