@import url("https://fonts.googleapis.com/css?family=Lato");

* {
  box-sizing: border-box;
}

body {
  /* background-image: linear-gradient(135deg, #f5f8fa 0%, #ecf0f7 100%); */

  display: flex;
  align-items: center;
  flex-direction: column;

  min-height: 100vh;
  font-family: "Lato", sans-serif;
  margin: 0;
}

.counter-timer {
  border: 1px solid #ccc;
  padding: 20px 40px 30px 40px;
  margin: 20px 60px;
  border-radius: 10px;
  background-color: #fff;
}

.counter-timer > h1 {
  margin-top: 5px;
  text-align: center;
}

.counter-timer > h4 {
  text-align: center;
}
.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  color: #111;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 10px;
  padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
}

.countdown-item span {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}
