.add-to-calendar > .react-add-to-calendar > .btn > .react-add-to-calendar__button {
    color: #fff;
}
.add-to-calendar > .react-add-to-calendar > .link-list > ul {
    font-size:18px;
    list-style: none;
    padding: 10px 0;
}

.add-to-calendar > .react-add-to-calendar > .link-list > ul > li {
    padding: 5px;
 }

 .add-to-calendar > .react-add-to-calendar > .link-list > ul > li > a > i {
     margin-right: 6px;
 }

 .thing-to-do {
     margin:20px 0 20px 0;
 }

 .location {
    padding: 12px;
    background: #eef8ff;
    border-radius: 4px;
    border: 1px solid rgb(74, 144, 226);
 }
 .location h4 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 4px;
 }
 .location h5 {
    font-size: 20px;
    margin: 0 0 6px 0;
 }

 .location h5.at {
     margin-top: 0;
     margin-bottom: 8px;

 }