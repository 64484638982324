body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Open Sans", sans-serif;
}
h2 {
  font-size: 26px;
}

.top-hero {
  background: url("../assets/img/pictures/hometopbg-blur.jpg") no-repeat;
  background-size: cover;
  width: 100%;
  color: #fff;
}
.intro-blurb {
  margin: 20px 0 40px 0;
  padding: 10px 20px;
  border-radius: 4px;
  background: rgba(18, 108, 166, 0.8);
}
.intro-blurb > h1,
.intro-form > h1 {
  margin-top: 0;
  margin-bottom: 6px;
}
.intro-blurb h3.subheading,
.intro-form h3.subheading {
  font-size: 22px;
  margin-top: 0px;
}
.intro-form h3.subheading {
  color: rgb(7, 150, 240);
  font-weight: 700;
  font-size: 28px;
}

.intro-blurb > h2 {
  font-size: 30px;
  line-height: 130%;
}
.intro-blurb > p {
  font-size: 18px;
  line-height: 140%;
}

.intro-form {
  width: 430px;
  color: #333;
  background: rgba(221, 243, 253, 0.7);
  border: 1px solid rgba(221, 243, 253, 0.9);
  border-top: none;
  border-bottom: none;

  margin: 0 auto 0 auto;
  border-radius: 0;
  padding: 20px 20px 20px 20px;
}
@media screen and (max-width: 940px) {
  .intro-form {
    width: 380px;
  }
  .intro-form > h1 {
    font-size: 36px;
  }
}
@media screen and (max-width: 768px) {
  .intro-form {
    width: 100%;
  }
  .intro-form > h1 {
    font-size: 32px;
  }
}
.intro-form .reserve-section {
  margin: 30px 0 10px 0;
  text-align: center;
}
.intro-form .reserve-section button.reserve-orange {
  height: 40px;
  width: 280px;
  padding: 5px 10px;
  background: #e68c24;
  text-align: center;
  border: 0;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}
@media screen and (max-width: 768px) {
  .intro-form .reserve-section button.reserve-orange {
    width: 90%;
  }
}
@media screen and (max-width: 558px) {
  .intro-form .reserve-section button.reserve-orange {
    width: 100%;
    font-size: 16px;
  }
}
.intro-form .reserve-section button.reserve-orange:hover {
  background: #eca554;
}
.intro-form .reserve-section button.reserve-orange:disabled {
  background: #c5af95;
}
.intro-form .reserve-section .confidential {
  margin-top: 5px;
  font-size: 15px;
  text-align: center;
}

.seminars {
  padding: 0 10px;
  font-size: 16px;
}

.as-seen-in {
  margin: 10px 0 5px 0;
}
.as-seen-in h2 {
  color: rgb(38, 125, 148);
  font-size: 26px;
  text-align: center;
}
.as-seen-in .icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.as-seen-in .icons > div {
  flex: 0 0 160px;
  justify-content: center;
  margin-bottom: 25px;
}

.discover {
  background: #fff;
  padding-bottom: 40px;
}

.discover h2 {
  color: rgb(7, 150, 240);
  font-size: 24px;
  font-weight: 600;
}
.discover h3.subhead {
  color: rgb(7, 150, 240);
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin: 10px 0 40px 0;
}
.discover table {
  border: none;
}
.discover td {
  vertical-align: top;
  padding: 10px;
}
.discover .fa-check {
  font-size: 40px;
  color: rgb(38, 148, 104);
}
.discover td.bullet {
  font-size: 20px;
  color: rgb(7, 150, 240);
}

.venues {
  padding: 20px 0;
  background: #fff;
}

.venues h2 {
  font-size: 26px;
  color: rgb(7, 150, 240);
  text-align: center;
  margin: 0 0 20px 0;
}
.venues .location {
  width: 100%;
  border: 1px solid #72929e;
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 8px;
  background: #e8f5ff;
}

.venues .location h3 {
  margin-top: 0;
  font-size: 24px;
  color: #444;
}
.venues .location h4 {
  font-size: 15px;
  margin-bottom: 0;
}

.students {
  background: rgb(38, 125, 148);
}
.students h2 {
  font-size: 26px;
  color: #fff;
  margin-bottom: 30px;
}
.students .row-eq-height {
  display: flex;
}
.students .card {
  color: #fff;
  padding: 20px;
  border-radius: 15px;
  background: rgb(101, 171, 189);
  height: 100%;
  margin-bottom: 20px;
}

.students .card p {
  margin: 0 20px;
  text-align: center;
}
.students .card h3.name {
  font-size: 20px;
  text-align: center;
  margin: 15px 0 5px 0;
}
.students .card h3.uni {
  margin: 0;
  font-size: 16px;
  color: #ccc;
  text-align: center;
}

.students .card .person-pic {
  text-align: center;
}
.students .reserve-section {
  margin: 50px 0 40px 0;
  text-align: center;
}
.discover .reserve-section {
  margin: 20px 0 10px 0;
  text-align: center;
}

.bonus {
  padding-top: 30px;
  padding-bottom: 30px;
}
.bonus h2 {
  color: rgb(38, 125, 148);
}
.bonus .bonuses {
  display: flex;
}
.bonus .image {
  flex: 1 1 420px;
}
.bonus .text {
  flex: 1 1 100%;
  padding-left: 40px;
}
.bonus .text .block {
  height: 180px;
}
.bonus .text h2 {
  font-size: 20px;
  color: #666;
}
.bonus .text h3 {
  font-size: 24px;
  margin-top: 0;
  color: rgb(38, 125, 148);
}
.bonus .text p {
  font-size: 16px;
}
.bonus .reserve-section {
  margin: 40px 0 30px 0;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .bonus .bonuses {
    display: block;
  }
  .bonus .image {
    text-align: center;
    width: 100%;
  }
  .bonus .image img {
    width: 100%;
  }
  .bonus .text {
    text-align: center;
    padding-left: 0;
  }
  .bonus .text .block {
    height: unset;
  }
}
.dan {
  padding-top: 20px;
  padding-bottom: 30px;
  background: rgb(38, 125, 148);
  color: #fff;
}
.dan h2 {
  font-size: 32px;
}
.dan h3 {
  font-size: 20px;
}
.dan p {
  font-size: 16px;
}
.dan .wistia {
  width: 500px;
  float: right;
  margin: 0 0 30px 30px;
}
@media screen and (max-width: 920px) {
  .dan .wistia {
    display: block;
    width: 100%;
    margin: 30px 0 30px 0;
  }
}
.footer {
  text-align: center;
  background: rgb(38, 125, 148);
  padding: 20px;
  margin-top: 10px;
}
.footer h2 {
  font-size: 32px;
  color: #fff;
}
.footer .reserve-section {
  margin: 40px 0 30px 0;
  text-align: center;
}
.reserve-section a.reserve-orange {
  height: 50px;
  width: 280px;
  padding: 10px 20px;
  background: #e68c24;
  text-align: center;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}
.reserve-section a.reserve-orange:hover {
  background: #eca554;
}
@media screen and (max-width: 558px) {
  .reserve-section a.reserve-orange {
    width: 100%;
    font-size: 16px;
  }
}
.venues .container,
.as-seen-in .container,
.discover .container,
.students .container,
.bonus .container,
.dan .container {
  padding: 0 30px;
}

@media screen and (max-width: 1100px) {
  .venues .container,
  .as-seen-in .container,
  .discover .container,
  .students .container,
  .bonus .container,
  .dan .container {
    padding: 0 30px;
  }
}

@media screen and (max-width: 920px) {
  .venues .container,
  .as-seen-in .container,
  .discover .container,
  .students .container,
  .bonus .container,
  .dan .container {
    padding: 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .students .row-eq-height {
    display: block;
  }
  .venues .container,
  .as-seen-in .container,
  .discover .container,
  .students .container,
  .bonus .container,
  .dan .container {
    padding: 0 20px;
  }
}

.select-has-error {
  border-color: #e74c3c;
}
.select-has-error > div {
  border-color: #e74c3c;
}
.select-has-error > div > div {
  border-color: #e74c3c;
}

.ButtonLink {
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  color: #18bc9c;
  padding: 0;
}

.ButtonLink:hover,
.ButtonLink:focus {
  text-decoration: underline;
}
