h1 {
  font-size: 2em;
}
.color-silver {
  background-color: #ddd;
}
.color-font-aqua {
  color: #7fdbff;
}
.color-aqua {
  background-color: #7fdbff;
}
.generic-center {
  margin: auto;
  padding: 2rem;
  border: 2px solid #000;
  text-align: center;
}
.dummy-height {
  min-height: 500px;
}
.border {
  border: 2px solid #000;
}
.buttons {
  border: 2px solid #000;
  padding: 20px;
}
.borderless {
  border: none;
}
.App {
  text-align: center;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}
.App-header {
  height: 150px;
  padding: 20px;
}
.App-intro {
  font-size: large;
}
.login-transition {
  text-align: center;
  padding-top: 50vh;
}
.debugger {
  text-align: left;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-default {
  background: #eef4f5 !important;
  border: #d6ddde 1px solid !important;
  color: #333 !important;
}

.first-title {
  margin-top: 0;
}

button:focus,
a:focus,
div:focus,
span:focus {
  outline: none;
}

.pagination > li > a,
.pagination > li > span {
  color: #4a4a4a;
  background-color: #f1f1f1;
}

.navbar-default {
  border-radius: 0;
}

@media (min-width: 1400px) {
  .container {
    width: 1370px;
  }
}

@media screen and (max-width: 768px) {
  table.responsive {
    border: 0;
  }

  table.responsive caption {
    font-size: 1.3em;
  }

  table.responsive thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table.responsive tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  table.responsive td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  table.responsive td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table.responsive td:last-child {
    border-bottom: 0;
  }
}

.xvisible-sm {
  display: none;
}

@media screen and (max-width: 768px) {
  .xhidden-sm {
    display: none;
  }

  .xvisible-sm {
    display:block;
  }
}